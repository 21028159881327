import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { NavigationBar } from '@capgo/capacitor-navigation-bar';
import { ThemeColors } from '@app/shared/interfaces/theme-colors';
import { ThemeService } from '@SavandBros/savandbros-ngx-common';
import { ThemeStyles } from '@app/shared/interfaces/theme-styles';

@Injectable({ providedIn: 'root' })
export class CapacitorService {
  /** Current status bar color. */
  private statusBarColors: ThemeColors = {
    light: '',
    dark: '',
  };

  /** Current status bar style (text color). */
  private statusStyle: ThemeStyles = {
    light: Style.Default,
    dark: Style.Default,
  };

  /** Current navigation bar color. */
  private navigationBarColors: ThemeColors = {
    light: '',
    dark: '',
  };

  /** Determines whether current platform is native. */
  public readonly isNative: boolean = Capacitor.isNativePlatform();

  constructor(private themeService: ThemeService) {}

  /** @returns Proper theme color based on current theme. */
  public getColor(colors: ThemeColors): string {
    if (this.themeService.isDark) {
      return colors.dark;
    }
    return colors.light;
  }

  /**
   * Listen to theme changes, and dynamically update
   * Navigation bar and Status bar colors.
   */
  public initiate(): void {
    if (!this.isNative) {
      return;
    }
    this.themeService.themeChange.subscribe({
      next: (): void => {
        this.setNavigationColor();
        this.setStatusColor();
        this.setStatusStyle();
      },
    });
  }

  /**
   * Set status bar color.
   *
   * @param colors Light and Dark colors to use
   * for status bar. By default, it will use
   * previous given colors.
   */
  public setStatusColor(colors: ThemeColors = this.statusBarColors): void {
    if (!this.isNative) {
      return;
    }
    this.statusBarColors = colors;
    StatusBar.setBackgroundColor({ color: this.getColor(colors) });
  }

  /**
   * Set status bar style (text color).
   *
   * @param styles Status bar style. By default,
   * it's previous given style.
   */
  public setStatusStyle(styles: ThemeStyles = this.statusStyle): void {
    if (!this.isNative) {
      return;
    }
    let style: Style = styles.light;
    if (this.themeService.isDark) {
      style = styles.dark;
    }
    this.statusStyle = styles;
    StatusBar.setStyle({ style });
  }

  /**
   * Set navigation bar color.
   *
   * @param colors Light and Dark colors to use
   * for navigation bar. By default, it will use
   * previous given colors.
   */
  public setNavigationColor(colors: ThemeColors = this.navigationBarColors): void {
    if (!this.isNative) {
      return;
    }
    this.navigationBarColors = colors;
    NavigationBar.setNavigationBarColor({ color: this.getColor(colors) });
  }

  /**
   * Set the same light and dark color for both
   * status bar and navigation bar.
   *
   * @param colors Light and Dark colors.
   * @param styles Status bar styles (text color).
   */
  public setColor(colors: ThemeColors, styles: ThemeStyles): void {
    this.setStatusStyle(styles);
    this.setStatusColor(colors);
    this.setNavigationColor(colors);
  }
}
